/* app/controls/control.pathway-carousel */

import Flickity from 'flickity';
import 'flickity-as-nav-for';
import 'flickity-fade';
import 'flickity-imagesloaded';

let $pathwayCarousels;

const arrowShape = {
	x0: 10,
	x1: 60, y1: 50,
	x2: 65, y2: 45,
	x3: 20
};

const pathwayCarouselSettings = {
	prevNextButtons: true,
	arrowShape: arrowShape,
	autoPlay: false,
	wrapAround: true,
	cellAlign: 'left',
	pageDots: false,
	draggable: false
};

var PathwayCarousel = {
	init: function($elms) {

		$pathwayCarousels = $elms;

		for (let i = 0; i < $pathwayCarousels.length; i++) {
			PathwayCarousel._initFlickity($pathwayCarousels[i]);
		}
	},

	_initFlickity: function (pathwayCarousel) {
		const thisFlick = new Flickity(pathwayCarousel, pathwayCarouselSettings);
	}
};

export { PathwayCarousel };
